<template>
  <v-container fluid grid-list-xl>
    <v-layout justify-space-around row v-if="bancali.length">
      <v-flex>
        <v-chip color="secondary" text-color="white">
          <v-avatar class="secondary darken-4">{{ bancali.length }}</v-avatar>
          Bancal<span v-if="bancali.length===1">e</span><span v-else>i</span>
        </v-chip>
      </v-flex>
      <v-flex sm3 class="text-xs-right">
          <v-chip small color="grey dark-3" text-color="white">
            Bancali selezionati: {{selectedBancali.length}}
          </v-chip>
          <v-menu offset-y :disabled="!selectedBancali.length">
            <v-btn
              slot="activator"
              :color="!selectedBancali.length ? 'grey lighten-1': 'primary'"
              dark>
              Azioni
              <v-icon>arrow_drop_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile v-if="selectedBancali.length > 1" @click="unisciBancali">
                <v-list-tile-title>Unisci</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="stampaCartello">
                <v-list-tile-title>Stampa cartello</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
      </v-flex>
    </v-layout>
    <v-layout row wrap>
      <v-flex xs12>
        <v-form v-model="validFormBancali" ref="formBancali" lazy-validation v-if="bancali.length">
          <v-list two-line class="mb-4">
            <v-list-group
              no-action
              sub-group
              :value="false"
              v-for="(bancale, i) in bancali"
              :key="i">
              <v-list-tile slot="activator">
                <v-list-tile-action @click.stop.prevent="preventSelected()">
                  <v-checkbox v-model="selectedBancali" v-if="!bancale.inviato" :value="bancale.bancale_id" />
                </v-list-tile-action>
                <v-list-tile-content>
                  <v-list-tile-title><span class="font-weight-black">Bancale {{ bancale.bancale_id }}</span></v-list-tile-title>
                  <v-list-tile-sub-title>
                    {{bancale.componenti.length}} component<span v-if="bancale.componenti.length===1">e</span><span v-else>i</span>
                    - Qtà {{ totaleBancale(bancale) }}
                  </v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
              <v-list-tile v-for="(componente, j) in bancale.componenti" :key="j">
                <v-list-tile-content>
                  <v-list-tile-title class="rowWrapper">
                    <v-layout :key="j" row wrap class="rowWrapper-row">
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Opera"
                          v-model="componente.opera"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Descrizione"
                          v-model="componente.descrizione_lavorazione"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg1>
                        <v-text-field
                          label="Codice"
                          v-model="componente.codice"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg1>
                        <v-text-field
                          label="Componente"
                          v-model="componente.descrizione"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Tipo lavorazione"
                          v-model="componente.tipo_lavorazione"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg2>
                        <v-text-field
                          label="Note"
                          v-model="componente.note"
                          disabled />
                      </v-flex>
                      <v-flex sm12 lg1>
                        <v-text-field
                          label="Quantità effettiva*"
                          :disabled="bancale.inviato > 0"
                          v-model.number="componente.qta_effettiva"
                          :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]" />
                      </v-flex>
                      <v-flex sm12 lg1>
                        <v-tooltip right>
                          <v-icon
                            v-if="bancale.componenti.length > 1"
                            @click.stop="scorporaComponente(componente)"
                            class="mt-0"
                            color="primary"
                            slot="activator">
                            eject
                          </v-icon>
                          Scorpora dal bancale
                        </v-tooltip>
                        <v-btn
                          small
                          v-if="j === 0 && bancale.componenti.length === 1 && !bancale.inviato"
                          @click="duplicaBancale(componente)">
                          Duplica bancale
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-list-tile-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list-group>
          </v-list>
          <v-btn
            class="ml-0 mt-4"
            color="primary"
            :disabled="!validFormBancali"
            @click.native="save">
            Salva
          </v-btn>
        </v-form>
        <v-layout v-if="!bancali.length">
          <v-flex sm12>
            <v-alert
              outline
              color="info"
              value="true"
              v-if="!bancali.length">
              Nessun bancale trovato
            </v-alert>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _reduce from 'lodash/reduce'
export default {
  data: () => ({
    ingresso: null,
    bancali: [],
    validFormBancali: false,
    selectedBancali: []
  }),
  methods: {
    getBancali () {
      this.$plsqlMethod('ingresso', 'get_bancali', { ingresso: this.$route.params.id })
        .then((response) => {
          this.bancali = response.data
          this.selectedBancali = []
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile recuperare i bancali' })
        })
    },
    save () {
      if (this.$refs.formBancali.validate()) {
        this.$plsqlMethod('ingresso', 'savebancali', this.bancali.filter(b => !b.inviato))
          .then((response) => {
            $EventBus.$emit('message', { type: 'success', text: 'Bancali salvati' })
          })
          .catch((err) => {
            console.log(err)
            $EventBus.$emit('message', { type: 'error', text: 'Impossibile salvare i bancali' })
          })
      } else {
        $EventBus.$emit('message', { type: 'error', text: 'Inserisci prima tutte le quantità effettive' })
      }
    },
    preventSelected () {
      return false
    },
    scorporaComponente (componente) {
      this.$plsqlMethod('ingresso', 'scorpora', { id: componente._id })
        .then((response) => {
          this.getBancali()
          $EventBus.$emit('message', { type: 'success', text: 'Componente scorporato' })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile scorporare il componente' })
        })
    },
    totaleBancale (bancale) {
      return _reduce((bancale.componenti), (sum, c) => {
        return sum + c.qta_effettiva
      }, 0)
    },
    unisciBancali () {
      this.$plsqlMethod('ingresso', 'unisci', { bancali: this.selectedBancali })
        .then((response) => {
          this.getBancali()
          $EventBus.$emit('message', { type: 'success', text: 'Bancali uniti' })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile unire i bancali' })
        })
    },
    duplicaBancale (componente) {
      this.$plsqlMethod('ingresso', 'duplica', { id: componente._id })
        .then((response) => {
          this.getBancali()
          $EventBus.$emit('message', { type: 'success', text: 'Bancale duplicato' })
        })
        .catch((err) => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Impossibile duplicare il bancale' })
        })
    },
    stampaCartello () {
      let toPrint = this.bancali.filter((b) => this.selectedBancali.indexOf(b.bancale_id) !== -1).map((b) => { b.sede = this.ingresso.sede; return b })
      this.$http({
        method: 'post',
        url: `/pdf/bancali`,
        responseType: 'blob',
        data: { bancali: toPrint }
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa dei bancali' })
      })
    },
    getIngresso () {
      this.$plsqlRead('ingresso', this.$route.params.id)
        .then(response => {
          this.ingresso = response.data
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', {
            type: 'error',
            text: 'Errore lettura ingresso'
          })
        })
    }
  },
  mounted () {
    this.getBancali()
    this.getIngresso()
  }
}
</script>

<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
</style>
<style>
  .v-list__group__header--sub-group .v-list__group__header__prepend-icon {
    padding-left: 20px!important;
  }
</style>
